import React from 'react'
// import FLogo from "../../public/Assets/Images/om-coder.png"
const Footer = () => {
  return (
    <>
      <footer>
      <div className='footer text-white text-center'>
           &copy; Copyright 2024 &nbsp;&nbsp;|&nbsp;&nbsp; <img src={process.env.PUBLIC_URL + '/Assets/Images/om-coder.png'} width={"100"} alt='om-Coder' />
          </div>
      </footer>
    </>
  )
}

export default Footer
